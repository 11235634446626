.formWithSections section {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  padding: 0px;
}

.formWithSections section:first-child {
  box-shadow: rgba(9, 30, 66, 0.26) 2px 4px 3px -3px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 10px;
  z-index: 2;
  padding: 10px;
}

.cover {
  background: #f3f4f8;
  height: 13px;
  left: 445px;
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 1;
}

.outputList {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
  display: block;
  font-size: 14px;
  list-style: none;
  min-height: 32px;
  padding: 4px 11px;
  width: 100%;

  & li {
    padding-left: 12px;
    position: relative;

    &::before {
      color: rgba(0, 0, 0, 0.45);
      content: '▶';
      font-size: 8px;
      left: 0;
      position: absolute;
      top: 6px;
    }
  }
}

.collapse {
  background: #fff;
  padding: 0px;

  & > :global(.ant-collapse-item) {
    border: none;

    & > :global(.ant-collapse-header) {
      padding: 0px 10px 0px 30px;
      z-index: 0;

      & > :global(.ant-collapse-expand-icon) {
        padding: 4px 0px;
      }

      & :global(.ant-collapse-arrow) {
        position: absolute;
        top: 24px;
        left: 0px;
        padding: 10px;
      }

      & > :global(.ant-collapse-header-text) {
        flex-grow: 1;
      }

      & :global(.ant-row) {
        cursor: default;
      }
    }
  }
}
