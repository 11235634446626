.collapse {
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;

  & > :global(.ant-collapse-item) {
    & > :global(.ant-collapse-header) {
      align-items: center;
      display: flex;
      z-index: 0;

      & > :global(.ant-collapse-header-text) {
        display: flex;
        flex-grow: 1;

        & > :global(.ant-row) {
          flex-grow: 1;
        }
      }
    }
  }
}

.description span {
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  margin: 5px 0;
}

.description div p {
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  min-height: 32px;
  padding: 5px;
  white-space: pre-wrap;
}

.inputOutputList {
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  display: block;
  font-size: 14px;
  list-style: none;
  min-height: 32px;
  padding: 4px 11px;
  width: 100%;

  & li {
    padding-left: 12px;
    position: relative;

    &::before {
      color: rgba(0, 0, 0, 0.45);
      content: '▶';
      font-size: 8px;
      left: 0;
      position: absolute;
      top: 6px;
    }
  }
}
