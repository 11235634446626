@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600&display=swap");

html {
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-weight: 400;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-weight: 600;
}

.staging::before {
  background: #da364a;
  color: #fff;
  content: 'STAGING';
  padding: 0 30px;
  position: fixed;
  right: -30px;
  top: 15px;
  transform: rotate(45deg);
  z-index: 1000;
}

.ant-form-item-label > label .ant-form-item-tooltip {
  margin-left: 4px;
}

.ant-form-item-explain {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.ant-layout-sider-zero-width-trigger {
  background: rgba(0, 21, 41, 0.6);

  &:hover {
    background: rgba(0, 21, 41, 1);
  }
}

.resource-index {
  background: white;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  bottom: 10px;
  left: 45px;
  position: fixed;
  top: 10px;
}

.resource-content {
  margin-left: 435px;

  & > section {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 10px;
  }
}

.ant-collapse-arrow {
  z-index: 2;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px 10px;
}

.ant-menu-vertical > .ant-menu-item {
  margin-bottom: 0 !important;
}

.display-block {
  display: block;

  & > .ant-upload {
    display: block;
  }
}
