.formWithSections section {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  padding: 10px;
}

.formWithSections section:first-child {
  box-shadow: rgba(9, 30, 66, 0.26) 2px 4px 3px -3px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 10px;
  z-index: 2;
}

.cover {
  background: #f3f4f8;
  height: 13px;
  left: 445px;
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 1;
}
