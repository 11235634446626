.tree :global(.ant-tree-treenode) {
  background: transparent;
  border-top: 1px solid #d9d9d9;
  padding: 9px;
}

.tree :global(.ant-tree-treenode span a) {
  color: rgba(0, 0, 0, 0.85);
}

.tree :global(.ant-tree-treenode:nth-child(odd):hover) {
  background: #f3f4f8;
}

.tree :global(.ant-tree-indent-unit) {
  width: 16px;
}

.tree :global(.ant-tree-switcher) {
  width: 16px;
}

.tree :global(.ant-tree-node-selected) {
  font-weight: bold;
  background: transparent !important;
}

.tree.alternate :global(.ant-tree-treenode:nth-child(even)) {
  background: #f4f4f4;
}
