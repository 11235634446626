.resourceIndex {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  & header,
  & .header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 8px 16px;

    & .icon {
      line-height: 32px;
      margin-right: 8px;
    }

    & h3 {
      flex: 1;
      line-height: 32px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & :global(.ant-btn) {
      margin-left: 8px;
    }

    & :global(.ant-input-affix-wrapper) {
      margin-top: 8px;
    }
  }

  & table {
    border-collapse: collapse;
    width: 100%;

    & tr {
      cursor: pointer;

      &:hover {
        background: #f3f4f8;
      }

      &:nth-child(even) {
        background: #f4f4f4;
      }

      & > td {
        border-top: 1px solid #d9d9d9;

        & a {
          color: rgba(0, 0, 0, 0.85);
          display: block;
          padding: 9px;

          &:global(.active) {
            font-weight: bold;
          }
        }
      }
    }
  }

  & .message {
    padding: 8px;
    text-align: center;
  }
}
