.login {
  align-items: center;
  background: linear-gradient(45deg, #0e62af, #006db4);
  display: flex;
  justify-content: center;
  min-height: 100vh;

  & form {
    background: white;
    border-radius: 3px;
    max-width: 360px;
    padding: 32px 32px 14px 32px;
    width: 100%;
  }

  & .logo {
    display: block;
    margin: 0 auto 16px;
    max-width: 200px;
  }

  & h2 {
    text-align: center;
  }
}

