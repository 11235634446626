.quill :global(.ql-editor) {
  font-family: "Fira Sans", sans-serif;
  font-size: 14px;
  line-height: 22px;
  height: 100px;
  padding: 4px 11px;
  resize: vertical;
}

.quill :global(.ql-snow .ql-tooltip::before) {
  content: "Open URL:";
}

.quill :global(.ql-snow .ql-tooltip .ql-action::after) {
  content: "Opslaan";
}

.quill :global(.ql-snow .ql-tooltip .ql-remove::before) {
  content: "Verwijderen";
}

.quill :global(.ql-snow .ql-tooltip[data-mode="link"]::before) {
  content: "Voeg link in:";
}

.quill button {
  position: relative;
}

.quill :global(button)::after {
  background: white;
  font-size: 18px;
  left: -2px;
  line-height: 30px;
  position: absolute;
  top: -4px;
  width: 24px;
}

.quill :global(button.ql-color[value="red"])::after,
.quill :global(button.ql-color[value="black"])::after,
.quill :global(button.ql-background[value="yellow"])::after,
.quill :global(button.ql-background[value="white"])::after {
  content: "A";
}

.quill :global(button.ql-color[value="red"])::after {
  color: red;
}

.quill :global(button.ql-background[value="yellow"])::after {
  background: repeating-linear-gradient(
    -45deg,
    rgb(220, 220, 0),
    rgb(256, 256, 0) 10px,
    rgb(220, 220, 0) 10px,
    rgb(256, 256, 0) 20px
  );
}

.quill :global(button.ql-background[value="white"])::after {
  background: repeating-linear-gradient(
    -45deg,
    rgb(220, 220, 220),
    rgb(256, 256, 256) 10px,
    rgb(220, 220, 220) 10px,
    rgb(256, 256, 256) 20px
  );
}
