.dot {
  background-color: #999797;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  height: 20px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
}
