@import url(https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600&display=swap);
.Container_container__3e1Mh {
  margin: 0 auto;
  padding: 0 15px;
  width: 100%
}
.Container_container__3e1Mh.Container_noPadding__2lw8E {
    padding: 0;
  }

.CommentList_commentList__iSpRe {
  height: 300px;
  overflow: auto
}
.CommentList_commentList__iSpRe .ant-comment-content-detail {
    white-space: pre-wrap;
  }

.DocumentTextsForm_formWithSections__28E65 section {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  padding: 0px;
}

.DocumentTextsForm_formWithSections__28E65 section:first-child {
  box-shadow: rgba(9, 30, 66, 0.26) 2px 4px 3px -3px; /* Safari */
  position: sticky;
  top: 10px;
  z-index: 2;
  padding: 10px;
}

.DocumentTextsForm_cover__1iC_1 {
  background: #f3f4f8;
  height: 13px;
  left: 445px;
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 1;
}

.DocumentTextsForm_outputList__CWF-T {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
  display: block;
  font-size: 14px;
  list-style: none;
  min-height: 32px;
  padding: 4px 11px;
  width: 100%
}

.DocumentTextsForm_outputList__CWF-T li {
    padding-left: 12px;
    position: relative
  }

.DocumentTextsForm_outputList__CWF-T li::before {
      color: rgba(0, 0, 0, 0.45);
      content: '▶';
      font-size: 8px;
      left: 0;
      position: absolute;
      top: 6px;
    }

.DocumentTextsForm_collapse__1qRaK {
  background: #fff;
  padding: 0px
}

.DocumentTextsForm_collapse__1qRaK > .ant-collapse-item {
    border: none
  }

.DocumentTextsForm_collapse__1qRaK > .ant-collapse-item > .ant-collapse-header {
      padding: 0px 10px 0px 30px;
      z-index: 0
    }

.DocumentTextsForm_collapse__1qRaK > .ant-collapse-item > .ant-collapse-header > .ant-collapse-expand-icon {
        padding: 4px 0px;
      }

.DocumentTextsForm_collapse__1qRaK > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        position: absolute;
        top: 24px;
        left: 0px;
        padding: 10px;
      }

.DocumentTextsForm_collapse__1qRaK > .ant-collapse-item > .ant-collapse-header > .ant-collapse-header-text {
        flex-grow: 1;
      }

.DocumentTextsForm_collapse__1qRaK > .ant-collapse-item > .ant-collapse-header .ant-row {
        cursor: default;
      }

.InterpretationsCollapse_collapse__ynaNi {
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9
}

.InterpretationsCollapse_collapse__ynaNi > .ant-collapse-item > .ant-collapse-header {
      align-items: center;
      display: flex;
      z-index: 0
    }

.InterpretationsCollapse_collapse__ynaNi > .ant-collapse-item > .ant-collapse-header > .ant-collapse-header-text {
        display: flex;
        flex-grow: 1
      }

.InterpretationsCollapse_collapse__ynaNi > .ant-collapse-item > .ant-collapse-header > .ant-collapse-header-text > .ant-row {
          flex-grow: 1;
        }

.InterpretationsCollapse_description__BD7wx span {
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  margin: 5px 0;
}

.InterpretationsCollapse_description__BD7wx div p {
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  min-height: 32px;
  padding: 5px;
  white-space: pre-wrap;
}

.InterpretationsCollapse_inputOutputList__R-4cL {
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  display: block;
  font-size: 14px;
  list-style: none;
  min-height: 32px;
  padding: 4px 11px;
  width: 100%
}

.InterpretationsCollapse_inputOutputList__R-4cL li {
    padding-left: 12px;
    position: relative
  }

.InterpretationsCollapse_inputOutputList__R-4cL li::before {
      color: rgba(0, 0, 0, 0.45);
      content: '▶';
      font-size: 8px;
      left: 0;
      position: absolute;
      top: 6px;
    }

.PoliciesForm_form__1ZGqf {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  box-shadow: rgba(9, 30, 66, 0.26) 2px 4px 3px -3px;
  margin-bottom: 10px;
  padding: 10px; /* Safari */
  position: sticky;
  top: 10px;
  z-index: 2;
}

.PoliciesForm_cover__3Ef99 {
  background: #f3f4f8;
  height: 13px;
  left: 445px;
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 1;
}

.PoliciesForm_policyId__1a7If {
  color: rgba(0, 0, 0, 0.55);
  font-size: 16px;
  margin-left: 8px;
}

.PoliciesForm_document__2VEGE h4 {
    margin-bottom: 10px;
  }

.PoliciesForm_document__2VEGE + .PoliciesForm_document__2VEGE {
    margin-top: 20px;
  }

.PoliciesForm_bulkButton__3eMUl {
  position: absolute;
  top: 14px;
  right: 10px;
  z-index: 1;
}

.PoliciesForm_outputList__fs35- {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
  display: block;
  font-size: 14px;
  list-style: none;
  min-height: 32px;
  padding: 4px 11px;
  width: 100%
}

.PoliciesForm_outputList__fs35- li {
    padding-left: 12px;
    position: relative
  }

.PoliciesForm_outputList__fs35- li::before {
      color: rgba(0, 0, 0, 0.45);
      content: '▶';
      font-size: 8px;
      left: 0;
      position: absolute;
      top: 6px;
    }

.ResourceIndex_resourceIndex__2xws5 {
  display: flex;
  flex-flow: column nowrap;
  height: 100%
}
.ResourceIndex_resourceIndex__2xws5 header,
  .ResourceIndex_resourceIndex__2xws5 .ResourceIndex_header__12gOZ {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 8px 16px
  }
.ResourceIndex_resourceIndex__2xws5 header .ResourceIndex_icon__2Hrle, .ResourceIndex_resourceIndex__2xws5 .ResourceIndex_header__12gOZ .ResourceIndex_icon__2Hrle {
      line-height: 32px;
      margin-right: 8px;
    }
.ResourceIndex_resourceIndex__2xws5 header h3, .ResourceIndex_resourceIndex__2xws5 .ResourceIndex_header__12gOZ h3 {
      flex: 1 1;
      line-height: 32px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
.ResourceIndex_resourceIndex__2xws5 header .ant-btn, .ResourceIndex_resourceIndex__2xws5 .ResourceIndex_header__12gOZ .ant-btn {
      margin-left: 8px;
    }
.ResourceIndex_resourceIndex__2xws5 header .ant-input-affix-wrapper, .ResourceIndex_resourceIndex__2xws5 .ResourceIndex_header__12gOZ .ant-input-affix-wrapper {
      margin-top: 8px;
    }
.ResourceIndex_resourceIndex__2xws5 table {
    border-collapse: collapse;
    width: 100%
  }
.ResourceIndex_resourceIndex__2xws5 table tr {
      cursor: pointer
    }
.ResourceIndex_resourceIndex__2xws5 table tr:hover {
        background: #f3f4f8;
      }
.ResourceIndex_resourceIndex__2xws5 table tr:nth-child(even) {
        background: #f4f4f4;
      }
.ResourceIndex_resourceIndex__2xws5 table tr > td {
        border-top: 1px solid #d9d9d9
      }
.ResourceIndex_resourceIndex__2xws5 table tr > td a {
          color: rgba(0, 0, 0, 0.85);
          display: block;
          padding: 9px
        }
.ResourceIndex_resourceIndex__2xws5 table tr > td a.active {
            font-weight: bold;
          }
.ResourceIndex_resourceIndex__2xws5 .ResourceIndex_message__3oGMj {
    padding: 8px;
    text-align: center;
  }

.RichText_quill__2xz9X .ql-editor {
  font-family: "Fira Sans", sans-serif;
  font-size: 14px;
  line-height: 22px;
  height: 100px;
  padding: 4px 11px;
  resize: vertical;
}

.RichText_quill__2xz9X .ql-snow .ql-tooltip::before {
  content: "Open URL:";
}

.RichText_quill__2xz9X .ql-snow .ql-tooltip .ql-action::after {
  content: "Opslaan";
}

.RichText_quill__2xz9X .ql-snow .ql-tooltip .ql-remove::before {
  content: "Verwijderen";
}

.RichText_quill__2xz9X .ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "Voeg link in:";
}

.RichText_quill__2xz9X button {
  position: relative;
}

.RichText_quill__2xz9X button::after {
  background: white;
  font-size: 18px;
  left: -2px;
  line-height: 30px;
  position: absolute;
  top: -4px;
  width: 24px;
}

.RichText_quill__2xz9X button.ql-color[value="red"]::after,
.RichText_quill__2xz9X button.ql-color[value="black"]::after,
.RichText_quill__2xz9X button.ql-background[value="yellow"]::after,
.RichText_quill__2xz9X button.ql-background[value="white"]::after {
  content: "A";
}

.RichText_quill__2xz9X button.ql-color[value="red"]::after {
  color: red;
}

.RichText_quill__2xz9X button.ql-background[value="yellow"]::after {
  background: repeating-linear-gradient(
    -45deg,
    rgb(220, 220, 0),
    rgb(256, 256, 0) 10px,
    rgb(220, 220, 0) 10px,
    rgb(256, 256, 0) 20px
  );
}

.RichText_quill__2xz9X button.ql-background[value="white"]::after {
  background: repeating-linear-gradient(
    -45deg,
    rgb(220, 220, 220),
    rgb(256, 256, 256) 10px,
    rgb(220, 220, 220) 10px,
    rgb(256, 256, 256) 20px
  );
}

.TermsForm_formWithSections__3Dk1N section {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  padding: 10px;
}

.TermsForm_formWithSections__3Dk1N section:first-child {
  box-shadow: rgba(9, 30, 66, 0.26) 2px 4px 3px -3px; /* Safari */
  position: sticky;
  top: 10px;
  z-index: 2;
}

.TermsForm_cover__LC7-O {
  background: #f3f4f8;
  height: 13px;
  left: 445px;
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 1;
}

.Dot_dot__293g5 {
  background-color: #999797;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  height: 20px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
}

.DocumentTextsIndex_tree__Oyeq4 .ant-tree-treenode {
  background: transparent;
  border-top: 1px solid #d9d9d9;
  padding: 9px;
}

.DocumentTextsIndex_tree__Oyeq4 .ant-tree-treenode span a {
  color: rgba(0, 0, 0, 0.85);
}

.DocumentTextsIndex_tree__Oyeq4 .ant-tree-treenode:nth-child(odd):hover {
  background: #f3f4f8;
}

.DocumentTextsIndex_tree__Oyeq4 .ant-tree-indent-unit {
  width: 16px;
}

.DocumentTextsIndex_tree__Oyeq4 .ant-tree-switcher {
  width: 16px;
}

.DocumentTextsIndex_tree__Oyeq4 .ant-tree-node-selected {
  font-weight: bold;
  background: transparent !important;
}

.DocumentTextsIndex_tree__Oyeq4.DocumentTextsIndex_alternate__356-K .ant-tree-treenode:nth-child(even) {
  background: #f4f4f4;
}

.Login_login__1UgLh {
  align-items: center;
  background: linear-gradient(45deg, #0e62af, #006db4);
  display: flex;
  justify-content: center;
  min-height: 100vh
}
.Login_login__1UgLh form {
    background: white;
    border-radius: 3px;
    max-width: 360px;
    padding: 32px 32px 14px 32px;
    width: 100%;
  }
.Login_login__1UgLh .Login_logo__326q7 {
    display: block;
    margin: 0 auto 16px;
    max-width: 200px;
  }
.Login_login__1UgLh h2 {
    text-align: center;
  }


html {
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-weight: 400;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-weight: 600;
}

.staging::before {
  background: #da364a;
  color: #fff;
  content: 'STAGING';
  padding: 0 30px;
  position: fixed;
  right: -30px;
  top: 15px;
  transform: rotate(45deg);
  z-index: 1000;
}

.ant-form-item-label > label .ant-form-item-tooltip {
  margin-left: 4px;
}

.ant-form-item-explain {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.ant-layout-sider-zero-width-trigger {
  background: rgba(0, 21, 41, 0.6)
}

.ant-layout-sider-zero-width-trigger:hover {
    background: rgba(0, 21, 41, 1);
  }

.resource-index {
  background: white;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  bottom: 10px;
  left: 45px;
  position: fixed;
  top: 10px;
}

.resource-content {
  margin-left: 435px
}

.resource-content > section {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    margin-bottom: 10px;
    padding: 10px;
  }

.ant-collapse-arrow {
  z-index: 2;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px 10px;
}

.ant-menu-vertical > .ant-menu-item {
  margin-bottom: 0 !important;
}

.display-block {
  display: block
}

.display-block > .ant-upload {
    display: block;
  }

